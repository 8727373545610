@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
  font-family: 'Averta';
  src: url('/public/Averta.otf'); /*URL to font*/
}
@font-face {
  font-family: 'AvertaExtraBold';
  src: url('/public/AvertaExtraBold.otf'); /*URL to font*/
}


body {
  margin: 0;
  font-family: 'Averta', Arial, sans-serif !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
html {
  position: relative;
  min-height: 100%;
  scroll-behavior: smooth;
}

.pace {
	-webkit-pointer-events: none;
	pointer-events: none;

	-webkit-user-select: none;
	-moz-user-select: none;
	user-select: none;
}

.pace-inactive {
	display: none;
}

.pace .pace-progress {
	background: #FFF;
	position: fixed;
	z-index: 2000;
	top: 0;
	right: 100%;
	width: 100%;
	height: 2px;
}